/**
 * Maritime Connectivity Platform Service Registry API
 * Maritime Connectivity Platform Service Registry, developed by the MCC MSR WG
 *
 * OpenAPI spec version: 0.0.8
 * Contact: info@maritimeconnectivity.net
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Coordinate } from './coordinate';
import { CoordinateSequence } from './coordinateSequence';
import { Envelope } from './envelope';
import { Geometry } from './geometry';
import { GeometryFactory } from './geometryFactory';
import { PrecisionModel } from './precisionModel';

export interface Point { 
    envelope?: Geometry;
    factory?: GeometryFactory;
    userData?: any;
    coordinates?: Array<Coordinate>;
    geometryType?: string;
    coordinateSequence?: CoordinateSequence;
    coordinate?: Coordinate;
    boundaryDimension?: number;
    dimension?: number;
    numPoints?: number;
    boundary?: Geometry;
    simple?: boolean;
    x?: number;
    y?: number;
    empty?: boolean;
    numGeometries?: number;
    precisionModel?: PrecisionModel;
    interiorPoint?: Point;
    envelopeInternal?: Envelope;
    srid?: number;
    rectangle?: boolean;
    area?: number;
    centroid?: Point;
    length?: number;
    valid?: boolean;
}